import React from 'react';
import { Flex, Text, Button } from '@chakra-ui/react';
import { useRouter } from 'next/router';

interface IErrorPageProps {
  header: string;
  description?: string;
  link?: {
    text: string;
    path?: string;
  };
  img: JSX.Element;
}

export const ErrorPage = ({
  header,
  description,
  img: Img,
  link,
}: IErrorPageProps): JSX.Element => {
  const router = useRouter();
  const handleGoToMenu = () => {
    if (link?.path) {
      router.push(link.path);
    } else {
      router.back();
    }
  };
  return (
    <Flex
      role="main"
      flexDir="column"
      bg="base.50"
      minH="100vh"
      justify="center"
      align="center"
      w="100vw"
      pb={{
        base: 4,
        md: 10,
      }}
    >
      <Flex flexDir="column" align="center">
        {Img ? Img : null}
        <Text
          textStyle="h1"
          as="h1"
          align="center"
          my={{ base: 4, md: 6 }}
          ml={{ base: 4, md: 0 }}
          fontSize={{ base: 24, md: 30 }}
        >
          {header || ''}
        </Text>
        {description ? (
          <Text
            textStyle="p"
            align="center"
            role="paragraph"
            my={{ base: 2, md: 6 }}
            ml={{ base: 4, md: 0 }}
            fontSize={{ base: 16, md: 22 }}
          >
            {description}
          </Text>
        ) : null}
        {link ? (
          <Button
            px={0}
            variant="tertiary"
            colorScheme="primary"
            textDecor="underline"
            fontWeight="normal"
            size="md"
            onClick={handleGoToMenu}
          >
            {link.text}
          </Button>
        ) : null}
      </Flex>
    </Flex>
  );
};
